import React, { useEffect, useState } from 'react';
import { StyleSheet, Image, TouchableOpacity, Dimensions, Text, View,FlatList } from 'react-native';
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import * as actionCreator from '../store/actions/action'
import { Card, Title, Paragraph, Button, Avatar, Appbar } from 'react-native-paper';
import { TextDefault, TextSubheader } from '../components/Custom'
import styles from '../styles/styles'
import moment from 'moment'

import Layout from '../constants/Layout'

const LeftContent = (props: any) => <Avatar.Image size={50} source={props} style={{ backgroundColor: 'white', }} />


export default function AdminScreen() {
    const [respWidth, setRespWidth] = useState(Layout.window.width)
    const [respHeight, setRespHeight] = useState(Layout.window.height)
    useEffect(() => {
        Dimensions.addEventListener('change', (e) => {
            const { width, height } = e.window;
            setRespWidth(width)

        })
    });
//sorting array by date (moment)
    // const arr = [
    //   { _id: 1, createdAt: moment('Sat Jan 07 2018 11:50:21 GMT+0000 (WET)') },
    //   { d_id: 2, createdAt: moment('Sat Jan 06 2018 11:50:21 GMT+0000 (WET)') }
    // ];
    
    // const newarr = arr.sort((a, b) => {
    //   return moment(a.createdAt).diff(b.createdAt);
    // });


    const { messages } = useSelector((state: any) => state.messageReducer, shallowEqual)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(actionCreator.populateMessage())
    }, []);

    return (
        <View style={styles.container}>
        <Appbar.Header >
          {/* <Appbar.BackAction onPress={goBack} /> */}
          {/* <Appbar.Action icon="magnify" onPress={()=>console.log('search')} /> */}
          <Appbar.Content title={'Messages'} style={{ alignItems: 'center' }} />
          {/* <Appbar.Action icon="magnify" onPress={() => console.log('search')} /> */}
          {/* <Appbar.Action icon="dots-vertical" onPress={()=>console.log('more')} /> */}
        </Appbar.Header>
        <View style={{ flex: 7, alignSelf: 'stretch' }}>
          <FlatList
            style={{ flex: 1, alignSelf: 'stretch', }}
            contentContainerStyle={{}}
            data={messages}
            //numColumns={4}
            renderItem={({ item, index }) =>
              <Card elevation={5} style={{ alignSelf: 'stretch', marginVertical: 5, marginLeft: respWidth > 800 ? Layout.window.width / 4 : 0, marginRight: respWidth > 800 ? Layout.window.width / 4 : 0, }} >
                <Card.Title title={<TextSubheader>{item.email}</TextSubheader>} subtitle={moment(item.date).format("dddd, MMMM Do YYYY, h:mm:ss a")}  />
                <Card.Content>
              
                  <TextDefault>{item.message}</TextDefault>
                </Card.Content>
  
              </Card>
              // <View style={{ borderWidth: 1,marginLeft: respWidth > 600 ? Layout.window.width / 4 : 0, marginRight: respWidth > 600 ? Layout.window.width / 4 : 0, }}>
              //   <Text>
              //     respWidth
              //   </Text>
              // </View>
  
            }
            keyExtractor={(item, index) => index.toString()}
          />
          < View style={{ marginHorizontal: 50, alignSelf: 'stretch' }}>
  
  
          </View >
        </View>
      </View >
    );
}

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     alignItems: 'center',
//     justifyContent: 'center',
//     paddingHorizontal: Layout.window.width / 4,

//   },
//   title: {
//     fontSize: 20,
//     fontWeight: 'bold',
//   },
//   separator: {
//     marginVertical: 10,
//     height: 1,
//     width: '80%',
//   },
// });
