import { Ionicons } from '@expo/vector-icons';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';



import * as SplashScreen from 'expo-splash-screen';
import * as React from 'react';

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();

        // Load fonts
       


        await Asset.loadAsync([
          require('../assets/images/splash.png'),
          require('../assets/images/icon.png'),
          require('../assets/images/portfolio/bxcess-1.png'),
          require('../assets/images/portfolio/bxcess-2.png'),
          require('../assets/images/portfolio/bxcess-3.png'),
          require('../assets/images/portfolio/bxcess-4.png'),
          require('../assets/images/portfolio/bxcess-5.png'),
          require('../assets/images/portfolio/bxcess-6.png'),
          require('../assets/images/portfolio/bxcess-7.png'),
          require('../assets/images/portfolio/lf-1.png'),
          require('../assets/images/portfolio/lf-2.png'),
          require('../assets/images/portfolio/lf-3.png'),
          require('../assets/images/portfolio/lunawallet-1.png'),
          require('../assets/images/portfolio/lunawallet-2.png'),
          require('../assets/images/portfolio/lunawallet-3.png'),
          require('../assets/images/portfolio/lunawallet-4.png'),
          require('../assets/images/portfolio/lunawan-1.png'),
          require('../assets/images/portfolio/lunawan-2.png'),
          require('../assets/images/portfolio/mayamall-1.png'),
          require('../assets/images/portfolio/mayamall-2.png'),
          require('../assets/images/portfolio/mayamall-3.png'),
          require('../assets/images/portfolio/mayamall-4.png'),
          require('../assets/images/portfolio/mayamall-1.png'),

          require('../assets/images/portfolio/niyo-1.png'),
          require('../assets/images/portfolio/niyo-2.png'),
          require('../assets/images/portfolio/niyo-3.png'),
          require('../assets/images/portfolio/niyo-4.png'),
          require('../assets/images/portfolio/niyo-5.png'),
          require('../assets/images/portfolio/niyo-6.png'),
          require('../assets/images/portfolio/niyo-7.png'),
          require('../assets/images/portfolio/niyo-8.png'),
          require('../assets/images/portfolio/niyo-9.png'),
          require('../assets/images/portfolio/niyo-10.png'),
          require('../assets/images/portfolio/tent-1.png'),
          require('../assets/images/portfolio/tent-2.png'),
          require('../assets/images/portfolio/tent-3.png'),
          require('../assets/images/portfolio/tent-4.png'),
          require('../assets/images/portfolio/tent-5.png'),
          require('../assets/images/portfolio/tent-6.png'),

          require('../assets/images/portfolio/rest1.webp'),
          require('../assets/images/portfolio/rest2.webp'),
          require('../assets/images/portfolio/rest3.webp'),








        ])
      }
      catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}
