import { Ionicons } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { getFocusedRouteNameFromRoute } from '@react-navigation/native';

import * as React from 'react';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import TabOneScreen from '../screens/TabOneScreen';
import TabTwoScreen from '../screens/TabTwoScreen';
import { BottomTabParamList, TabOneParamList, TabTwoParamList } from '../../types';
import DetailScreen from '../screens/DetailScreen';
import AdminScreen from '../screens/AdminScreen';
import LoginScreen from '../screens/LoginScreen';

// const BottomTab = createBottomTabNavigator<BottomTabParamList>();

// function getRouteName(route: any) {
//   // If the focused route is not found, we need to assume it's the initial screen
//   // This can happen during if there hasn't been any navigation inside the screen
//   // In our case, it's "Feed" as that's the first screen inside the navigator
//   const routeName = getFocusedRouteNameFromRoute(route) ?? 'Feed';

//   return routeName
// }


// export default function BottomTabNavigator() {
//   const colorScheme = useColorScheme();

//   return (
//     <BottomTab.Navigator
//       initialRouteName="TabOne"
//       tabBarOptions={{ activeTintColor: Colors[colorScheme].tint }}>
//       <BottomTab.Screen
//         name="Home"
//         component={TabOneNavigator}
//         options={{
//           tabBarIcon: ({ color }:any) => <TabBarIcon name="ios-home" color={color} />,
//         }}
//       />
//       <BottomTab.Screen
//         name="Portfolio"
//         component={TabTwoNavigator}
//         options={({ route }:any) => {

//           let tabBarVisible = true
//           const routeName = getRouteName(route)
//           if (routeName === 'Detail') {
//             tabBarVisible = false
//           }
//           return {
//             tabBarIcon: ({ color }: any) => <TabBarIcon name="ios-list" color={color} />, tabBarVisible
//           }
//         }}
//       />
//     </BottomTab.Navigator>
//   );
// }

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
// function TabBarIcon(props: { name: string; color: string }) {
//   return <Ionicons size={30} style={{ marginBottom: -3 }} {...props} />;
// }

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const AdminStack = createStackNavigator();

export default function AdminNavigator() {
  return (
    <AdminStack.Navigator headerMode={'none'}>
      <AdminStack.Screen
        name="Admin"
        component={AdminScreen}
        options={{ headerTitle: 'Tab One Title' }}
      />
         <AdminStack.Screen
        name="Login"
        component={LoginScreen}
        options={{ headerTitle: 'Tab One Title' }}
      />

    </AdminStack.Navigator>
  );
}

