import React, { useEffect, useState } from 'react';
import { StyleSheet, Image, TouchableOpacity, Dimensions, Text, View } from 'react-native';
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import * as actionCreator from '../store/actions/action'
import { Button, TextInput } from 'react-native-paper';
import { TextDefault, TextSubheader } from '../components/Custom'
import styles from '../styles/styles'

import Layout from '../constants/Layout'



export default function LoginScreen() {
    const [respWidth, setRespWidth] = useState(Layout.window.width)
    const [respHeight, setRespHeight] = useState(Layout.window.height)
    useEffect(() => {
        Dimensions.addEventListener('change', (e) => {
            const { width, height } = e.window;
            setRespWidth(width)

        })
    });


    // const encode = (data: any) => {
    //   return Object.keys(data)
    //     .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    //     .join("&");
    // }

    // const handleSubmit = () => {
    //   fetch("/", {
    //     method: "POST",
    //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //     body: encode({ "form-name": "contact", message, email })
    //   })
    //     .then(() => {
    //       //alert(`message ialah :${message}`);
    //       setFormVisible(!formVisible)
    //     })
    //     .catch(error => alert(error));

    // };

    const handleSubmit = () => {
        dispatch(actionCreator.login(email, password))
    }

    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')


    const { loggedIn } = useSelector((state: any) => state.loginReducer, shallowEqual)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(actionCreator.checkLogin())
    }, []);

    return (
        <View style={[styles.container, { justifyContent: 'center', alignItems: 'center', marginVertical: 5, paddingLeft: respWidth > 800 ? Layout.window.width / 4 : 10, paddingRight: respWidth > 800 ? Layout.window.width / 4 : 10, }]}>
            <Image source={require('../assets/images/icon.png')} style={{ height: 151, width: 151 }} />
            <TextSubheader >SYA CREATIVE SOLUTIONS</TextSubheader>
            {/* <View style={styles.separator} /> */}
            <TextDefault style={{ textAlign: 'center' }}>We Develop & Customize Cross-Platform Applications to Support Your Business Needs       </TextDefault>
            {/* <Text>Leave your message below:</Text> */}
            <View style={{ alignSelf: 'stretch', paddingVertical: 10, }}>
    <Text>{JSON.stringify(loggedIn)}</Text>
                <View style={{ alignSelf: 'stretch', paddingVertical: 10, }}>

                    <>

                        <TextInput mode={'outlined'} placeholder={`E-mail Address`} onChangeText={val => setEmail(val)} style={respHeight < 500 && { fontSize: 15, height: 25 }} />
                        <TextInput mode={'outlined'} placeholder={`Password`} secureTextEntry onChangeText={val => setPassword(val)} style={respHeight < 500 && { fontSize: 15, height: 25 }} />

                        <Button style={{ marginTop: 10 }} icon="information" mode="contained" onPress={handleSubmit}>
                            Login
  </Button>
                    </>

                </View>
            </View>
        </View>
    );
}

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     alignItems: 'center',
//     justifyContent: 'center',
//     paddingHorizontal: Layout.window.width / 4,

//   },
//   title: {
//     fontSize: 20,
//     fontWeight: 'bold',
//   },
//   separator: {
//     marginVertical: 10,
//     height: 1,
//     width: '80%',
//   },
// });
