interface portfolioStructure {
  [index: number]: {
    title: string;
    date: string;
    tech: string;
    desc: string;
    feature: string;
    screenshots: any;
    note: string;
  };

}



const portfolio = [
  {
    title: 'Development of Restaurant Order Management App',
    date: 'September 2020',
    tech: 'React Native, Expo',
    desc: 'Assisting restaurant/stall owners and operators manage their food business more efficiently.    ',
    feature: 'Notification, Schedule Display, Countdown',
    psCaption:'BossOrder',
    playstore:`https://play.google.com/store/apps/details?id=com.syahnet.bossorder`,
    note: '',
    screenshots: [
      { screenShot: require('../assets/images/portfolio/rest1.webp'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/rest2.webp'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/rest3.webp'), caption: 'NA' },
    ]
  },
  {
    title: 'Development of Prayer Schedule App',
    date: 'August 2020',
    tech: 'React Native, Expo',
    desc: 'Simple app to determine prayer time in Malaysia based on data extracted from JAKIM website ',
    feature: 'Notification, Schedule Display, Countdown',
    note: '',
    screenshots: [{ screenShot: require('../assets/images/icon.png'), caption: 'NA' }]
  },
  {
    title: 'Development of SyahNet.com',
    date: 'July 2020',
    tech: 'React Native, Expo',
    desc: 'Simple app accessible on multiple platform, i.e Web Browser, Android, IOS ',
    feature: 'Progressive Web Application',
    psCaption:'SyahNet',
    playstore:`https://play.google.com/store/apps/details?id=com.syahnet.portfolio`,
    note: '',
    screenshots: [{ screenShot: require('../assets/images/icon.png'), caption: 'NA' }]
  },
  {
    title: 'Development of Personal Expense Management',
    date: 'June 2020',
    tech: 'React Native, Expo',
    desc: 'To help manage personal expenses with data stored locally on the device',
    feature: 'Multiple accounts creation divided into income,expense and saving. Support custom expense type ',
    note: '',
    psCaption:'MoneyTracker',
    playstore:`https://play.google.com/store/apps/details?id=com.syahnet.moneytracker`,

    screenshots: [
      { screenShot: require('../assets/images/portfolio/lunawan-1.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/lunawan-2.png'), caption: 'NA' },
    ]
  },
  {
    title: 'Enhancement of Digital Account',
    date: 'August 2020',
    tech: 'React Native, Expo',
    desc: 'Mobile app front-end for existing web-based system proposed to financial-related agencies etc ',
    feature: 'PIN authentication, biometric authentication, push notification, invoice creation, loan status monitoring',
    note: '',
    psCaption:'Niyo',
    playstore:`https://play.google.com/store/apps/details?id=com.fcgb.da`,

    screenshots: [{ screenShot: require('../assets/images/portfolio/niyo-1.png'), caption: 'NA' },
    { screenShot: require('../assets/images/portfolio/niyo-2.png'), caption: 'NA' },
    { screenShot: require('../assets/images/portfolio/niyo-3.png'), caption: 'NA' },
    { screenShot: require('../assets/images/portfolio/niyo-4.png'), caption: 'NA' },
    { screenShot: require('../assets/images/portfolio/niyo-5.png'), caption: 'NA' },
    { screenShot: require('../assets/images/portfolio/niyo-6.png'), caption: 'NA' },
    { screenShot: require('../assets/images/portfolio/niyo-7.png'), caption: 'NA' },
    { screenShot: require('../assets/images/portfolio/niyo-8.png'), caption: 'NA' },
    { screenShot: require('../assets/images/portfolio/niyo-9.png'), caption: 'NA' },]
  },
  {
    title: 'Development of Loan Management App',
    date: 'July 2020',
    tech: 'React Native, Expo',
    desc: 'Mobile app front-end for existing web-based system proposed to financial-related agencies etc  ',
    feature: 'PIN authentication, biometric authentication, push notification, invoice creation, loan status monitoring, automated form, GPS Mapping ',
    note: '',
    psCaption:'Tent',
    playstore:`https://play.google.com/store/apps/details?id=com.fcgb.tekun`,
    
    screenshots: [
      { screenShot: require('../assets/images/portfolio/tent-1.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/tent-2.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/tent-3.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/tent-4.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/tent-5.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/tent-6.png'), caption: 'NA' },]
  },

  {
    title: 'Development of Lifestyle App',
    date: 'July 2020',
    tech: 'React Native, Expo',
    desc: 'Mobile app front-end for existing web-based system proposed to government agencies etc  ',
    feature: 'PIN authentication, push notification, online mall, e-learning, centralized bill payment facility',
    note: '',
    psCaption:'BXcess',
    playstore:`https://play.google.com/store/apps/details?id=com.fcgb.bxcess`,
    
    screenshots: [
      { screenShot: require('../assets/images/portfolio/bxcess-1.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/bxcess-2.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/bxcess-3.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/bxcess-4.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/bxcess-5.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/bxcess-6.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/bxcess-7.png'), caption: 'NA' },
    ]
  },
  {
    title: 'Development of Online Mall App',
    date: 'July 2020',
    tech: 'React Native, Expo',
    desc: 'Mobile app front-end for a web-based online mall',
    feature: 'Single Sign-on',
    note: '',
    screenshots: [
      { screenShot: require('../assets/images/portfolio/mayamall-1.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/mayamall-2.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/mayamall-3.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/mayamall-4.png'), caption: 'NA' },]
  },
  {
    title: 'Development of e-Wallet',
    date: 'July 2020',
    tech: 'React Native, Expo',
    desc: 'Mobile app featuring e-wallet functionalities ',
    feature: 'PIN authentication, QR Code, push notification,  centralized bill payment facility',
    note: '',
    psCaption:'LunaWallet',
    playstore:`https://play.google.com/store/apps/details?id=com.fcgb.lunawallet`,    
    screenshots: [
      { screenShot: require('../assets/images/portfolio/lunawallet-1.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/lunawallet-2.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/lunawallet-3.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/lunawallet-4.png'), caption: 'NA' },]
  },
  {
    title: 'Development of E-learning App',
    date: 'July 2020',
    tech: 'React Native, Expo, Amazon AWS, Firebase Realtime Database',
    desc: 'E-learning App to assist bonding between parents and kids',
    feature: 'Video streaming, video upload',
    note: '',
    screenshots: [
      { screenShot: require('../assets/images/portfolio/lf-1.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/lf-2.png'), caption: 'NA' },
      { screenShot: require('../assets/images/portfolio/lf-3.png'), caption: 'NA' },]
  },
]

export { portfolio }