import React, { useEffect, useState } from 'react';
import { Platform, Text, View, TextInput, Image, TouchableOpacity, FlatList, Dimensions } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
//import { Card, Box, FormatData, CustomText, TextDefault, TextError, TextHeader, TextSmall, TextSubheader, SearchBox,CustomTextInput, CustomFormAction } from '../components/Custom'
import styles from '../styles/styles'
const web = Platform.OS === 'web'

//KOTAK

const Card = (props: any) =>
    <View style={[props.flex && { flex: props.flex }]}>
        <View style={[styles.card, styles.bgWhite, styles.shadow, props.style && props.style]}>
            {props.children}
        </View>
    </View>

const Box = (props: any) =>
    <View style={props.flex && { flex: props.flex }}>
        <View style={[styles.box, props.style && props.style]}>
            {props.children}
        </View>
    </View>

const FormatData = (props: any) =>
    <>
        <View style={{ flexDirection: 'row' }}>
            <CustomText style={[styles.textDefault, { flex: 2 }]}>{props.label}</CustomText>
            <CustomText style={[styles.textDefault, { flex: 1 }]}>{props.data}</CustomText>
        </View>
    </>

const TextSmall = (props: any) => {
    const { color, style } = props
    return (
        <CustomText
            fontMul={0.8}
            style={[color && { color }, style && style]}
        >{props.children}</CustomText>
    )
}

const TextHeader = (props: any) => {
    const { color, style } = props
    return (
        <CustomText
            fontMul={1.5}
            style={[{ color: '#1faccf' }, color && { color }, style && style]}
        >{props.children}</CustomText>
    )
}

const TextSubheader = (props: any) => {
    const { color, style } = props
    return (
        <CustomText
            fontMul={1.3}
            style={[color && { color }, style && style]}
        >{props.children}</CustomText>
    )
}

const TextDefault = (props: any) => {
    const { color, style } = props
    return (
        <CustomText
            fontMul={1}
            style={[{color:'grey'},color && { color }, style && style]}
        >{props.children}</CustomText>
    )
}

const TextError = (props: any) => {
    const { color, style } = props
    return (
        <CustomText
            fontMul={0.8}
            style={[{ color: 'red' }, color && { color }, style && { ...style }]}
        >{props.children}</CustomText>
    )
}

const CustomText = (props: any) => {
    const [respWidth, setRespWidth] = useState(Dimensions.get('window').width)
    const [respHeight, setRespHeight] = useState(Dimensions.get('window').height)

    const { fontMul, color, } = props

    useEffect(() => {
        Dimensions.addEventListener('change', (e) => {
            const { width, height } = e.window;
            setRespWidth(width)
            setRespHeight(height)
        })
    });

    const fontSizeDefault = respWidth > 1500 ? 16 : respWidth > 1400 ? 14 : 13
    const fontSize = fontSizeDefault * fontMul
    return (
        <Text
            //numberOfLines={1}
            //ellipsizeMode={'head'}
            style={[props.style, { fontFamily: 'Montserrat_400Regular', fontSize, marginBottom:10,lineHeight:20 }, color && { color }]}
        >{props.children}</Text>
    )
}

// const SearchBox = (props: any) =>
//     <Box flex={props.flex}>
//         <Card flex={1} style={{ padding: 0, margin: 0, justifyContent: 'center', paddingLeft: 10 }}>
//             <View style={[{ flexDirection: 'row', justifyContent: 'center' }]}>
//                 <Ionicons name="ios-search" size={32} color="lightgrey" />
//                 <TextInput placeholder='Search' value={props.value} style={{ marginLeft: 10, flex: 1 }} />
//             </View>
//         </Card>
//     </Box>

// const CustomTextInput = (props: any) => {
//     const { value, handleChange, handleBlur, touched, error, label, keyboardType, placeholder, handleClick, message, secureText, disabled } = props
//     if (web) {
//         return (
//             !handleClick ?
//                 <View style={[{ marginBottom: 10, }]}>
//                     <View style={{ flexDirection: 'row', }}>
//                         <TextDefault style={[{ marginTop: 10, marginBottom: 10, flex: 1 }]}>{label}</TextDefault>
//                         <TextInput editable={!disabled} secureTextEntry={secureText} keyboardType={keyboardType} value={value} onChangeText={handleChange} onBlur={handleBlur} style={{ borderWidth: 1, borderRadius: 10, borderColor: touched && error ? 'rgba(255,0,0,1)' : 'rgba(0,0,0,0.3)', padding: 5, fontSize: 15, fontFamily: 'Montserrat_medium', flex: 2 }} placeholder={touched && error ? '' : placeholder} placeholderTextColor={touched && error ? 'rgba(255,0,0,1)' : 'lightgrey'} />
//                     </View>
//                     <View style={{ flexDirection: 'row', }}>
//                         <View style={{ flex: 1 }} />
//                         <View style={{ flex: 2 }}>{touched && error && <TextError>{error}</TextError>}
//                             {message && <TextError>{message.email}</TextError>}</View>
//                     </View>
//                 </View> :
//                 <View style={[{ marginBottom: 10, flexDirection: 'row' }]}>
//                     <TouchableOpacity onPress={handleClick}>
//                         <TextDefault style={[{ marginTop: 10, marginBottom: 10 }]}>{label}</TextDefault>
//                         <TextInput secureTextEntry={secureText} editable={false} value={value} onChangeText={handleChange} onBlur={handleBlur} style={{ borderWidth: 1, borderColor: touched && error ? 'rgba(255,0,0,1)' : 'rgba(0,0,0,0.3)', padding: 5, fontSize: 15, fontFamily: 'Montserrat_medium' }} placeholder={touched && error ? '' : placeholder} placeholderTextColor={touched && error ? 'rgba(255,0,0,1)' : 'lightgrey'} />
//                     </TouchableOpacity>
//                     {touched && error && <TextError>{error}</TextError>}
//                 </View>
//         )
//     } else {
//         return (
//             !handleClick ?
//                 <View style={[{ marginBottom: 10, alignSelf: 'stretch' }]}>
//                     <TextDefault style={[{ marginTop: 10, marginBottom: 10, }]}>{label}</TextDefault>
//                     <TextInput editable={!disabled} secureTextEntry={secureText} keyboardType={keyboardType} value={value} onChangeText={handleChange} onBlur={handleBlur} style={{ borderWidth: 1, borderColor: touched && error ? 'rgba(255,0,0,1)' : 'rgba(0,0,0,0.3)', padding: 5, fontSize: 15, fontFamily: 'Montserrat_medium', }} placeholder={touched && error ? '' : placeholder} placeholderTextColor={touched && error ? 'rgba(255,0,0,1)' : 'lightgrey'} />
//                     {touched && error && <Text style={styles.error}>{error}</Text>}
//                     {message && <TextError>{message.email}</TextError>}
//                 </View> :
//                 <View style={[{ marginBottom: 10, flexDirection: 'row' }]}>
//                     <TouchableOpacity onPress={handleClick}>
//                         <TextDefault style={[{ marginTop: 10, marginBottom: 10 }]}>{label}</TextDefault>
//                         <TextInput secureTextEntry={secureText} editable={false} value={value} onChangeText={handleChange} onBlur={handleBlur} style={{ borderWidth: 1, borderColor: touched && error ? 'rgba(255,0,0,1)' : 'rgba(0,0,0,0.3)', padding: 5, fontSize: 15, fontFamily: 'Montserrat_medium' }} placeholder={touched && error ? '' : placeholder} placeholderTextColor={touched && error ? 'rgba(255,0,0,1)' : 'lightgrey'} />
//                     </TouchableOpacity>
//                     {touched && error && <TextError>{error}</TextError>}
//                 </View>
//         )
//     }

// }

// const CustomFormAction = (props:any) => {
//     //const { isConnected, isInternetReachable, type } = useSelector(state => state.netInfoReducer, shallowEqual)
//     const { navigation, isValid, handleSubmit, authEnabled, locked, isSubmitting, label, boxStyle } = props
//     return (
//         <View style={{ flexDirection: 'row', alignSelf: 'stretch', }} >
//             <TouchableOpacity onPress={() => navigation.goBack()} style={[{ flex: 1, borderColor: '#D3D3D3', borderWidth: 1, paddingTop: 20, paddingBottom: 20, justifyContent: 'center', alignItems: 'center' }, boxStyle]}>
//                 <Text style={[styles.butang, { color: '#000000' }]}>Back</Text>
//             </TouchableOpacity>
//             {/* <TouchableOpacity disabled={!isValid || !isInternetReachable} onPress={handleSubmit} style={{ flex: 1 }}>
//                 <LinearGradient colors={(isValid && isInternetReachable) ? ['#0A6496', '#055E7C'] : ['rgba(10,100,150,0.5)', 'rgba(5,94,124,0.5)']} style={{ flex: 1, padding: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
//                     {isSubmitting ? <ActivityIndicator color={'#fff'} /> : <Text style={[styles.butang, { color: '#fff' }]}>{label ? label : 'Submit'}</Text>}
//                     {authEnabled ? locked ? <Ionicons name='ios-lock' color={'#fff'} style={{ fontSize: 30, paddingLeft: 20 }} /> : <View /> : <View />}
//                 </LinearGradient>
//             </TouchableOpacity> */}
//         </View>
//     )
// }


export { Card, Box, FormatData, CustomText, TextError, TextHeader, TextSmall, TextSubheader, TextDefault, }