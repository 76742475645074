import { Ionicons } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { getFocusedRouteNameFromRoute } from '@react-navigation/native';

import * as React from 'react';


import PrivacyScreen from '../screens/PrivacyScreen';


const InfoStack = createStackNavigator();

export default function InfoNavigator() {
  return (
    <InfoStack.Navigator headerMode={'none'}>
      <InfoStack.Screen
        name="Privacy"
        component={PrivacyScreen}
        options={{ headerTitle: 'Privacy' }}
      />
    
    </InfoStack.Navigator>
  );
}

