import React, { useEffect, useState } from 'react';
import { StyleSheet, Image, TouchableOpacity, Dimensions, Text, View } from 'react-native';
import { Button, TextInput } from 'react-native-paper';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextDefault, TextSubheader } from '../components/Custom'
import styles from '../styles/styles'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import * as actionCreator from '../store/actions/action'
import Layout from '../constants/Layout'



export default function TabOneScreen() {
  const [respWidth, setRespWidth] = useState(Layout.window.width)
  const [respHeight, setRespHeight] = useState(Layout.window.height)
  useEffect(() => {
    Dimensions.addEventListener('change', (e) => {
      const { width, height } = e.window;
      setRespWidth(width)

    })
  });


  const validationSchema = Yup.object().shape({

    email: Yup
      .string()
      .email()
      .required()
      .label('Email'),
    message: Yup
      .string()
      .required()
      .label('Message'),

  });
  const dispatch = useDispatch()

  const [formVisible, setFormVisible] = useState(true)


  const handleSubmit = (values: any) => {
    dispatch(actionCreator.handleSubmit(values))
    setFormVisible(!formVisible)
  }

  return (
    <View style={[styles.container, { justifyContent: 'center', alignItems: 'center', marginVertical: 5, paddingLeft: respWidth > 800 ? Layout.window.width / 4 : 10, paddingRight: respWidth > 800 ? Layout.window.width / 4 : 10, }]}>
      <Image source={require('../assets/images/icon.png')} style={{ height: 151, width: 151 }} />
      <TextSubheader >SYA CREATIVE SOLUTIONS</TextSubheader>
      {/* <View style={styles.separator} /> */}
      <TextDefault style={{ textAlign: 'center' }}>We Develop & Customize Cross-Platform Applications to Support Your Business Needs       </TextDefault>
      {/* <Text>Leave your message below:</Text> */}
      <View style={{ alignSelf: 'stretch', paddingVertical: 10, }}>
        {formVisible ?
          <Formik
            initialValues={{
              email: null,
              message: null,
            }}
            onSubmit={(values: any) => {
              console.log(`kat screen : ${JSON.stringify(values)}`)
              handleSubmit(values)
            }}
            validationSchema={validationSchema}
            validateOnMount={true}
            isInitialValid={false}
          >
            {(FormikProps: any) => {
              const { email, message } = FormikProps.values
              const emailError = FormikProps.errors.email
              const emailTouched = FormikProps.touched.email
              const messageError = FormikProps.errors.message
              const messageTouched = FormikProps.touched.message
              return (
                <>

                  <TextInput mode={'outlined'} placeholder={`How can we help you?`} onChangeText={FormikProps.handleChange('message')} onBlur={FormikProps.handleBlur('message')} multiline numberOfLines={2} style={respHeight < 500 && { fontSize: 15, height: 50 }} />
                  <TextInput mode={'outlined'} placeholder={`E-mail Address`} onChangeText={FormikProps.handleChange('email')} onBlur={FormikProps.handleBlur('email')} style={respHeight < 500 && { fontSize: 15, height: 25 }} />

                  <Button disabled={!FormikProps.isValid} style={{ marginTop: 10 }} icon="information" mode="contained" onPress={FormikProps.handleSubmit}>
                    Send
      </Button>
                </>
              )
            }}
          </Formik >

          : <View style={{ alignSelf: 'stretch', alignItems: 'center' }}>
            <TextDefault style={{ justifyContent: 'center' }}>Thank you. We will get back to you shortly</TextDefault>
            <Button  style={{ marginTop: 10 }} icon="information" mode="contained" onPress={()=>setFormVisible(!formVisible)}>
            Ask Again
      </Button>
          </View>
        }
      </View>
    </View>
  );
}

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     alignItems: 'center',
//     justifyContent: 'center',
//     paddingHorizontal: Layout.window.width / 4,

//   },
//   title: {
//     fontSize: 20,
//     fontWeight: 'bold',
//   },
//   separator: {
//     marginVertical: 10,
//     height: 1,
//     width: '80%',
//   },
// });
