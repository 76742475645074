import React, { useState, useEffect } from 'react';
import { StyleSheet, Image, ScrollView, FlatList, Dimensions, TouchableOpacity, Text, View } from 'react-native';
import * as WebBrowser from 'expo-web-browser';

import { Card, Title, Paragraph, Button, Avatar, Appbar } from 'react-native-paper';
import Layout from '../constants/Layout'
import { portfolio } from '../db/data'
import moment from 'moment'
import styles from '../styles/styles'

import { TextDefault, TextSubheader } from '../components/Custom'
import { Subheader } from 'react-native-paper/lib/typescript/src/components/List/List';


export default function DetailScreen(props: any) {


    const [detailIndex, setDetailIndex] = useState(parseInt(props.route.params?.detailIndex ?? 'NA'))
    const [detail, setDetail] = useState(portfolio[detailIndex])
    const [selectedImage, setSelectedImage] = useState(detail.screenshots[0].screenShot ? detail.screenshots[0].screenShot : require('../assets/images/icon.png'))
    const [respWidth, setRespWidth] = useState(Layout.window.width)
    useEffect(() => {
        Dimensions.addEventListener('change', (e) => {
            const { width, height } = e.window;
            setRespWidth(width)

        })
    });

    const goBack = () => props.navigation.navigate('Portfolio')

    const next = () => {
        setDetailIndex(detailIndex + 1)
        setDetail(portfolio[detailIndex + 1])
        setSelectedImage(portfolio[detailIndex + 1].screenshots[0].screenShot ? portfolio[detailIndex + 1].screenshots[0].screenShot : require('../assets/images/icon.png'))
        //console.log(`next is ${detailIndex + 1} `)
        //console.log(portfolio.length)
    }

    const prev = () => {
        setDetailIndex(detailIndex - 1)
        setDetail(portfolio[detailIndex - 1])
        setSelectedImage(portfolio[detailIndex - 1].screenshots[0].screenShot ? portfolio[detailIndex - 1].screenshots[0].screenShot : require('../assets/images/icon.png'))
        //console.log(`prev is ${detailIndex - 1} `)
    }

    const _handlePressButtonAsync = async (uri) => {
        let result = await WebBrowser.openBrowserAsync(uri);
        //this.setState({ result });
      };
    

    return (
        <View style={styles.container}>
            <Appbar.Header >
                <Appbar.Action icon="view-list" onPress={goBack} />
                {detailIndex > 0 && <Appbar.BackAction onPress={prev} />}
                <Appbar.Content title={detail.title} style={{ alignItems: 'center' }} />
                {detailIndex < (portfolio.length - 1) && <Appbar.Action icon="arrow-right" onPress={next} />}
            </Appbar.Header>

            <ScrollView style={{ flex: 7, alignSelf: 'stretch', marginTop: 10 }} contentContainerStyle={{ paddingHorizontal: respWidth > 1200 ? Layout.window.width / 4 : 10 }} >
                <View style={{ height: Layout.window.height / 8 * 7, alignSelf: 'stretch', flexDirection: respWidth > 1200 ? 'row' : 'column', padding: 10 }}>
                    {respWidth > 1200 && <View style={{ flex: 1 }}>
                        <TextSubheader  >Description</TextSubheader>
                        <TextDefault style={{ paddingBottom: 20 }}>{detail.desc}</TextDefault>
                        <TextSubheader style={styles.textDefault}>Features</TextSubheader>
                        <TextDefault style={{ paddingBottom: 20 }}>{detail.feature}</TextDefault>
                        <TextSubheader>Technology Used</TextSubheader>
                        <TextDefault style={{ paddingBottom: 20 }}>{detail.tech}</TextDefault>
                        {detail.playstore&&<>
                        <TextSubheader>On Google Play</TextSubheader>
                        <TouchableOpacity onPress={()=>_handlePressButtonAsync(detail.playstore)}><TextDefault style={{ paddingBottom: 20,color:'cornflowerblue' }}>{detail.psCaption}</TextDefault></TouchableOpacity></>}
                    </View>
                    }
                    <View style={{ flex: 1, backgroundColor: 'dimgrey' }}>
                        <View style={{ flex: 7, padding: 10, backgroundColor: 'dimgrey' }}>
                            <Image source={selectedImage} resizeMode={'contain'} style={{ flex: 1, width: undefined, height: undefined, }} />
                        </View>
                        <View style={{ flex: 1, padding: 5, alignItems: 'center', backgroundColor: 'dimgrey' }}>
                            <FlatList
                                style={{}}
                                horizontal
                                data={detail.screenshots.slice(0, 3)}
                                //numColumns={4}
                                renderItem={({ item, index }) =>
                                    <TouchableOpacity onPress={() => setSelectedImage(item.screenShot)} style={{ flex: 1, width: 50, height: undefined, borderWidth: 1, borderColor: 'lightgrey', marginHorizontal: 5 }}>
                                        <Image source={item.screenShot} resizeMode={'cover'} style={{ flex: 1, width: 50, height: undefined, }} />
                                    </TouchableOpacity>
                                }
                                keyExtractor={(item, index) => index.toString()}
                            />
                        </View>
                    </View>
                </View>
                <View style={{ alignSelf: 'stretch', }}>
                    <View style={{ padding: 10 }}>
                        {respWidth <= 1200 && <>
                            <TextSubheader>Description</TextSubheader>
                            <TextDefault>{detail.desc}</TextDefault>
                            <TextSubheader>Features</TextSubheader>
                            <TextDefault>{detail.desc}</TextDefault>
                            <TextSubheader>Technology Used</TextSubheader>
                            <TextDefault>{detail.desc}</TextDefault>
                        </>}
                        <TextSubheader>Note</TextSubheader>
                        <TextDefault>{detail.note}</TextDefault>
                    </View>

                </View>
            </ScrollView>
        </View>
    );
}

// const styles = StyleSheet.create({
//     container: {
//         flex: 1,
//         alignItems: 'center',
//         justifyContent: 'flex-start',

//     },
//     title: {
//         fontSize: 20,
//         fontWeight: 'bold',
//     },
//     separator: {
//         marginVertical: 30,
//         height: 1,
//         width: '80%',
//     },
// });
