import React, { useEffect, useState } from 'react';
import { StyleSheet, Image, ScrollView, FlatList, Dimensions, View, Text } from 'react-native';
import { Card, Title, Paragraph, Button, Avatar, Appbar } from 'react-native-paper';
import Layout from '../constants/Layout'
import { TextDefault, TextSubheader } from '../components/Custom'

// import { Text, View } from '../components/Themed';
import styles from '../styles/styles'
import moment from 'moment'
import { portfolio } from '../db/data'

const LeftContent = (props: any) => <Avatar.Image size={50} source={props} style={{ backgroundColor: 'white', }} />

export default function TabTwoScreen(props: any) {
  const [respWidth, setRespWidth] = useState(Layout.window.width)
  useEffect(() => {
    Dimensions.addEventListener('change', (e) => {
      const { width, height } = e.window;
      setRespWidth(width)

    })
  });

  const goBack = () => props.navigation.goBack()
  return (
    <View style={styles.container}>
      <Appbar.Header >
        {/* <Appbar.BackAction onPress={goBack} /> */}
        {/* <Appbar.Action icon="magnify" onPress={()=>console.log('search')} /> */}
        <Appbar.Content title={'Portfolio'} style={{ alignItems: 'center' }} />
        {/* <Appbar.Action icon="magnify" onPress={() => console.log('search')} /> */}
        {/* <Appbar.Action icon="dots-vertical" onPress={()=>console.log('more')} /> */}
      </Appbar.Header>
      <View style={{ flex: 7, alignSelf: 'stretch' }}>
        <FlatList
          style={{ flex: 1, alignSelf: 'stretch', }}
          contentContainerStyle={{}}
          data={portfolio}
          //numColumns={4}
          renderItem={({ item, index }) =>
            <Card elevation={5} style={{ alignSelf: 'stretch', marginVertical: 5, marginLeft: respWidth > 800 ? Layout.window.width / 4 : 0, marginRight: respWidth > 800 ? Layout.window.width / 4 : 0, }} onPress={() => props.navigation.navigate('Detail', { detailIndex: index })}>
              <Card.Title title={<TextSubheader>{item.desc}</TextSubheader>} subtitle={item.date} left={() => LeftContent(item.screenshots.length > 0 ? item.screenshots[0].screenShot : require('../assets/images/favicon.png'))} />
              <Card.Content>
            
                <TextDefault>{item.desc}</TextDefault>
              </Card.Content>

            </Card>
            // <View style={{ borderWidth: 1,marginLeft: respWidth > 600 ? Layout.window.width / 4 : 0, marginRight: respWidth > 600 ? Layout.window.width / 4 : 0, }}>
            //   <Text>
            //     respWidth
            //   </Text>
            // </View>

          }
          keyExtractor={(item, index) => index.toString()}
        />
        < View style={{ marginHorizontal: 50, alignSelf: 'stretch' }}>


        </View >
      </View>
    </View >

  );
}


