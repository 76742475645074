import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          Home: {
            screens: {
              Home: 'home',
            },
          },
          Portfolio: {
            screens: {
              Portfolio: 'portfolio',
              Detail: 'detail'
            },

          },
        },
      },
      NotFound: '*',
      Admin: {
        screens: {
          Admin: 'admin',
          Login: 'login',

        },
      },
      Info: {
        screens: {
          Privacy: 'privacy',
          // Login: 'login',

        },
      },
    },
  },
};
